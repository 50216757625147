/* MODULES */
import Vue from 'vue'
import Router from 'vue-router'

/* COMPONENTS */
const index = () => import('../components/index.vue')
const Startseite = () => import('../components/Startseite.vue')
const Leistungen = () => import('../components/Leistungen.vue')
const Impressum = () => import('../components/Impressum.vue')
const Team = () => import('../components/Team.vue')
const Kontakt = () => import('../components/Kontakt.vue')
const Terminbuchung = () => import('../components/Terminbuchung.vue')
const Öffnungszeiten = () => import('../components/Oeffnungszeiten.vue')
Vue.use(Router)

const routes = [
  {
    path: '/',
    component: index,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'Startseite',
        component: Startseite,
      },
      {
        path: 'leistungen',
        name: 'Leistungen',
        component: Leistungen,
      },
      {
        path: 'team',
        name: 'Team',
        component: Team,
      },
      {
        path: 'kontakt',
        name: 'Kontakt',
        component: Kontakt,
      },
      {
        path: 'terminbuchung',
        name: 'Terminbuchung',
        component: Terminbuchung,
      },
      {
        path: 'oeffnungszeiten',
        name: 'Öffnungszeiten',
        component: Öffnungszeiten,
      },
      {
        path: 'impressum',
        name: 'Impressum',
        component: Impressum,
      },
    ],
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
})

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0)
  next()
})

export default router
