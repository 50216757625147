<template>
  <v-app
    :style="`
      background-image: url('/images/background01.png');
      ${
        $vuetify.breakpoint.mdAndUp
          ? 'background-position: center top;'
          : 'background-position-x: 75%; background'
      };
      background-size: cover;
      background-attachment: fixed;
    `"
    v-scroll.self="onScroll"
  >
    <v-app-bar
      app
      :color="offsetTop < 100 ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,1)'"
      light
      :class="$vuetify.breakpoint.mdAndUp ? 'pt-5 px-5' : undefined"
      flat
      style="z-index: 6000 !important;"
    >
      <v-app-bar-nav-icon
        x-large
        v-if="!menu"
        @click="menu = !menu"
      ></v-app-bar-nav-icon>
      <v-btn x-large icon color="#111111" v-if="menu" @click="menu = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer v-if="!$vuetify.breakpoint.mdAndUp"></v-spacer>
      <img
        alt="Vuetify Logo"
        class="mx-5 my-5"
        src="./assets/Header.png"
        :height="$vuetify.breakpoint.mdAndUp ? 60 : 45"
        @click="home()"
      />
      <v-spacer></v-spacer>
      <v-tooltip bottom content-class="text-center rounded-pill">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon href="tel:025759387788" v-on="on" v-bind="attrs">
            <v-icon>mdi-phone</v-icon>
          </v-btn>
        </template>
        <span class="font-weight-bold">Anrufen</span>
        <br />
        <small>02575 9387788</small>
      </v-tooltip>
      <v-tooltip bottom content-class="text-center rounded-pill">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-if="$vuetify.breakpoint.mdAndUp"
            href="mailto:mail@zahnarzt-reckenfeld.de"
            v-on="on"
            v-bind="attrs"
          >
            <v-icon>mdi-email</v-icon>
          </v-btn>
        </template>
        <span class="font-weight-bold">E-Mail schreiben</span>
        <br />
        <small>mail@zahnarzt-reckenfeld.de</small>
      </v-tooltip>
      <v-tooltip bottom content-class="text-center rounded-pill">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-if="$vuetify.breakpoint.mdAndUp"
            to="/terminbuchung"
            v-on="on"
            v-bind="attrs"
          >
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
        </template>
        <span class="font-weight-bold">Online Terminbuchung</span>
        <br />
        <small>
          Buchen Sie Ihren Termin einfach & schnell online!
        </small>
      </v-tooltip>
      <v-tooltip bottom content-class="text-center rounded-pill">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-if="$vuetify.breakpoint.mdAndUp"
            href="https://goo.gl/maps/PLmo7CmcK9WukoiH6"
            target="_blank"
            v-on="on"
            v-bind="attrs"
          >
            <v-icon>mdi-google-maps</v-icon>
          </v-btn>
        </template>
        <span class="font-weight-bold">Anfahrt</span>
        <br />
        <small>
          So finden Sie unsere Praxis
        </small>
      </v-tooltip>
      <v-btn
        rounded
        class="elevation-0"
        :color="closed ? 'error' : 'success'"
        v-if="$vuetify.breakpoint.mdAndUp"
        to="/oeffnungszeiten"
      >
        <b class="mr-2">{{ closed ? 'ÖFFNET' + tomorrow : 'GEÖFFNET' }}</b>
        {{ closed ? ' UM ' : ' BIS ' }} {{ close_open_in }} Uhr
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-slide-x-transition>
        <v-container
          v-if="menu"
          fluid
          style="
            position: fixed;
            top: 0;
            left: 0;
            overflow-y: scroll;
            overflow-x: hidden;
            height: 100vh;
            width: 100vw;
            z-index: 4000 !important;
          "
        >
          <v-row class="pa-0 h-100">
            <v-col
              cols="12"
              md="5"
              lg="3"
              class="pa-0"
              style="min-height: 100vh;"
            >
              <v-card
                color="white"
                light
                style="min-height: 100vh; padding-top: 10vh;"
              >
                <v-toolbar
                  v-if="false"
                  light
                  :class="$vuetify.breakpoint.mdAndUp ? 'pt-5 px-5' : undefined"
                  flat
                  color="transparent"
                >
                  <v-btn x-large icon color="#111111" @click="menu = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-spacer v-if="!$vuetify.breakpoint.mdAndUp"></v-spacer>
                  <img
                    alt="Vuetify Logo"
                    class="mx-5 my-5"
                    src="./assets/Header.png"
                    :height="$vuetify.breakpoint.mdAndUp ? 60 : 45"
                  />
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-phone</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-row class="mt-5 text-center">
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-btn
                      text
                      rounded
                      color="#8a101d"
                      dark
                      :x-large="$vuetify.breakpoint.mdAndUp"
                      :large="!$vuetify.breakpoint.mdAndUp"
                      to="/"
                      @click="menu = false"
                    >
                      <v-icon class="mr-2">mdi-home</v-icon>
                      Start
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-btn
                      rounded
                      text
                      color="#8a101d"
                      dark
                      :x-large="$vuetify.breakpoint.mdAndUp"
                      :large="!$vuetify.breakpoint.mdAndUp"
                      to="/leistungen"
                      @click="menu = false"
                    >
                      <v-icon class="mr-2">mdi-tooth</v-icon>
                      LEISTUNGEN
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-btn
                      rounded
                      text
                      color="#8a101d"
                      dark
                      :x-large="$vuetify.breakpoint.mdAndUp"
                      :large="!$vuetify.breakpoint.mdAndUp"
                      to="/team"
                      @click="menu = false"
                    >
                      <v-icon class="mr-2">mdi-account-group</v-icon>
                      Team
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-btn
                      rounded
                      text
                      color="#8a101d"
                      dark
                      :x-large="$vuetify.breakpoint.mdAndUp"
                      :large="!$vuetify.breakpoint.mdAndUp"
                      to="/kontakt"
                      @click="menu = false"
                    >
                      <v-icon class="mr-2">mdi-account-box</v-icon>
                      Kontakt
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-btn
                      text
                      rounded
                      color="#8a101d"
                      dark
                      :x-large="$vuetify.breakpoint.mdAndUp"
                      :large="!$vuetify.breakpoint.mdAndUp"
                      to="/terminbuchung"
                      @click="menu = false"
                    >
                      <v-icon class="mr-2">mdi-calendar</v-icon>
                      Online Terminbuchung
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-btn
                      text
                      rounded
                      color="#8a101d"
                      dark
                      :x-large="$vuetify.breakpoint.mdAndUp"
                      :large="!$vuetify.breakpoint.mdAndUp"
                      to="/oeffnungszeiten"
                      @click="menu = false"
                    >
                      <v-icon class="mr-2">mdi-clock</v-icon>
                      Öffnungszeiten
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col
              v-if="$vuetify.breakpoint.mdAndUp"
              cols="7"
              lg="9"
              :style="`height: 100vh; background: url('${require('./assets/team/Zahnärztinnen_Ausschnitt_web.jpg')}'); background-size: cover; background-position: center center;`"
            ></v-col>
          </v-row>
        </v-container>
      </v-slide-x-transition>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer
      :fixed="$vuetify.breakpoint.lgAndUp"
      v-if="$vuetify.breakpoint.lgAndUp"
      padless
      :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'transparent'"
    >
      <v-row justify="space-between" class="px-5">
        <v-col cols="auto">
          <v-btn text rounded to="/impressum">Impressum</v-btn>
        </v-col>
        <v-col class="text-center" cols="12" lg="auto">
          {{ new Date().getFullYear() }} —
          <strong>Zahnarztpraxis S. Wischniewski</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import router from './routes'

export default {
  name: 'App',
  data: () => ({
    offsetTop: 0,
    menu: false,
    timer: {
      tag: '',
      uhrzeit: '',
    },
    tomorrow: '',
    closed: false,
    close_open_in: '',
    openingHours: [
      {
        day: 'Sonntag',
        short: 'So',
        open: '',
        close: '',
      },
      {
        day: 'Montag',
        short: 'Mo',
        open: '08:00',
        close: '18:00',
      },
      {
        day: 'Dienstag',
        short: 'Di',
        open: '08:00',
        close: '18:00',
      },
      {
        day: 'Mittwoch',
        short: 'Mi',
        open: '08:00',
        close: '18:00',
      },
      {
        day: 'Donnerstag',
        short: 'Do',
        open: '08:00',
        close: '18:00',
      },
      {
        day: 'Freitag',
        short: 'Fr',
        open: '08:00',
        close: '13:00',
      },
      {
        day: 'Samstag',
        short: 'Sa',
        open: '',
        close: '',
      },
    ],
  }),
  mounted() {
    this.timer = this.getCurrentTime()
    console.log(this.timer)
    this.checkOpeningHours()
    this.updateTime()
  },
  methods: {
    home() {
      router.push('/')
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop
    },
    updateTime() {
      var datum = new Date()
      if (datum.getSeconds() == 0) {
        this.getCurrentTime()
        this.checkOpeningHours()
        setTimeout(() => {
          this.updateTime()
        }, 60000)
      } else {
        setTimeout(() => {
          this.updateTime()
        }, 1000)
      }
    },
    getCurrentTime() {
      var datum = new Date()
      return {
        tag: datum.getDay(),
        uhrzeit:
          this.addNull(datum.getHours()) +
          ':' +
          this.addNull(datum.getMinutes()),
      }
    },
    addNull(val) {
      if (val < 10) {
        return '0' + val
      }
      return val
    },
    checkOpeningHours() {
      if (this.timer.tag < 7) {
        var tag = this.openingHours[this.timer.tag]
        var next = ''
        if (this.timer.tag == 6 || this.timer.tag == 5) {
          next = this.openingHours[1]
          this.tomorrow = ' ' + next.day
        } else {
          next = this.openingHours[this.timer.tag + 1]
          this.tomorrow = ' Morgen'
        }

        if (this.timer.uhrzeit >= tag.open && this.timer.uhrzeit <= tag.close) {
          this.closed = false
          this.close_open_in = tag.close
        } else if (
          this.timer.uhrzeit >= tag.open &&
          this.timer.uhrzeit >= tag.close
        ) {
          this.closed = true
          this.close_open_in = next.open
        } else if (this.timer.uhrzeit < tag.open) {
          this.closed = true
          this.tomorrow = ''
          this.close_open_in = tag.open
        }
      }
    },
  },
}
</script>

<style>

  *::-webkit-scrollbar {
    display: none;
  }

  body, .v-application {
    scrollbar-width: none;
  }

  html {
    overflow: -moz-scrollbars-none;
    scrollbar-color: transparent transparent;
  }




</style>
